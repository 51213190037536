import React from "react";
import styled from "styled-components";

import "video.js/dist/video-js.css";

// Styled components

const Flexcontainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
`;

const Img = styled.img`
  height: 100%;
  width: 100%;
`;

export const Instaplaceholder = () => (
  <Flexcontainer>
    <Img src="/img/instagram.png" alt="instagram feed" />
  </Flexcontainer>
);

// {"access_token":"IGQVJYRG53ZAFhUY3hWVmJvOGVRdEw3QklfeWVvTGU2NEJMZAGdQbC0wZAm8xUkhHS3pxcWN3dmlCSWN2OFlSMmFkclZApbkFxeTI5SXJWZAFZAjYkJWd1BjZA0UybW1NWGZA4YVczNEdUbU1R","token_type":"bearer","expires_in":5176126}%
