import React from "react";
import styled from "styled-components";

const Img = styled.img`
  height: 80%;
  width: 80%;
  padding: 35px 0px 0px 0;
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;
export let Image = (props) => (
  <Container>
    <Img
      src={props.src}
      alt="carouselimg"
      style={{ width: props.width, marginTop: props.top,  maxWidth: props.max }}
    />
  </Container>
);
