import React from "react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.scss";
import "./Carousel.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

// Content
export const Carousel = ({ slides }) => {
  return (
    <Swiper
      pagination={{ clickable: true }}
      navigation
      spaceBetween={50}
      slidesPerView={1}    
    >
      {slides.map((slide) => (
        <SwiperSlide>
          {slide.type === "video" ? (
            <div
              style={{
                maxWidth: "100%",
                paddingTop: "56.25%",
                overflow: "hidden",
              }}
            >
              <iframe
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                }}
                title={slide.title}
                src={slide.src}
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          ) : (
            <img
              src={slide.src}
              alt="slide"
              style={{ height: "auto", width: "100%" }}
            />
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
