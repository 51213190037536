import React from "react";
import styled from "styled-components";
import { data } from "../data/data";

const Icons = styled.div`
  display: flex;
  flex-wrap:wrap;
  justify-content: center;
  align-items: center;
  padding: 0;
  
`;
const A = styled.a`
  margin: 3px;
`;

const Img = styled.img`
  border-bottom:1px solid black;
  height: 2rem;
  margin: 0 2px;
  padding: 2px;
`;

export let Socials = () => {
  return (
    <Icons>
      <A target="blank" href={data.contactinfo.facebook}>
        <Img src={data.imgpath.facebook} alt="facebook" />
      </A>
      <A target="blank" href={data.contactinfo.youtube}>
        <Img src={data.imgpath.youtube} alt="youtube" />
      </A>
      <A target="blank" href={data.contactinfo.spotify}>
        <Img src={data.imgpath.spotify} alt="spotify" />
      </A>
      <A target="blank" href={data.contactinfo.instagram}>
        <Img src={data.imgpath.instagram} alt="instagram" />
      </A>
      <A target="blank" href={data.contactinfo.soundcloud}>
        <Img src={data.imgpath.soundcloud} alt="instagram" />
      </A>
      <A target="blank" href={'mailto:' + data.contactinfo.email}>
        <Img src={data.imgpath.email} alt="instagram" />
      </A>
    </Icons>
  );
};
