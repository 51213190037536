import React from "react";
import { data } from "./data/data";
import "./styles/App.scss";
import Section from "./components/Section";
import { Spotify } from "./components/Spotify";
import Footer from "./components/Footer";
import { Contact } from "./components/Contact";
import { Carousel } from "./components/Carousel/Carousel";
import { Menu } from "./components/Menu";

import { Image } from "./components/utility_components/Image";
import { Recentie } from "./components/Recentie";
import { Socials } from "./components/Socials";
import { Buttonlink } from "./components/utility_components/Button";
import { Form } from "./components/Form";
import styled from "styled-components";
// import Fetch_IG_API from "./components/instagram_api/Fetch_IG_API";
import { Instaplaceholder } from "./components/instagram_api/Instaplaceholder";

const RecentiesFlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1100px;
  margin: auto;
  width: 100%;

  flex-wrap: wrap;
`;

function App() {
  return (
    <div className="App">
      <div id={data.titles.siteTitle} />
      {/* Menu */}
      <Menu />
      <Image src={data.imgpath.logo} width="80%" top="40px" max="600px" />
      <Socials />
      <Carousel
        slides={[
          { src: "/img/Hanna-4.jpg", type: "img" },
          { src: "/img/Hanna-3.jpg", type: "img" },
          { src: "/img/Hanna-2.jpg", type: "img" },
        ]}
      />

      {/* video */}
      <Section id={data.titles.section1} title={data.titles.section2}>
      <Carousel
        slides={[
          { src:"https://www.youtube.com/embed/o0yd5Xsc7kY", type:"video"},
          { src:"https://www.youtube.com/embed/x4mqSo1hFSQ", type:"video"},
          { src:"https://www.youtube.com/embed/0RzHfNiwmGY", type:"video"},
          { src:"https://www.youtube.com/embed/Vb8NvBt9x4U", type:"video"},
          { src:"https://www.youtube.com/embed/C7AER_tbxuo", type:"video"},
          
        ]}
      />
        <Buttonlink
          src={data.imgpath.btnabonneren}
          href={data.contactinfo.youtube}
        />
      </Section>
      {/* muziek */}
      <Section id={data.titles.section2}>
        <Spotify src={data.spotifypath.spotify1} />
        <Buttonlink
          src={data.imgpath.btnvolgen}
          href={data.contactinfo.spotify}
        />
      </Section>
      {/* bio */}

      <FlexWrapper>
        <Section id={data.titles.section3} min="350px">
          <Image src="/img/hanna-3.jpg" width="100%" />
          <p>{data.stories.bio}</p>
        </Section>
        {/* contactformulier */}

        <Section min="350px" id={data.titles.section6}>
          <Form />
        </Section>

        {/* insta */}
        <Section id={data.titles.section4} min="350px">
          <Instaplaceholder />

          <Buttonlink
            src={data.imgpath.btnvolgen}
            href={data.contactinfo.instagram}
          />
        </Section>

        {/* flexbox container to align recenties items in a pleasing way */}
        <div className="flex1">
          <div id={data.titles.section5}>
            <RecentiesFlexWrapper>
              <Recentie id={data.recenties[1].name}>
                <a
                  href={data.recenties[1].link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <blockquote>
                    <span>"</span>
                    {data.recenties[1].recentie}
                    <span>"</span>
                  </blockquote>
                </a>
              </Recentie>

              <Recentie id={data.recenties[2].name}>
                <a
                  href={data.recenties[2].link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <blockquote>
                    <span>"</span>
                    {data.recenties[2].recentie}
                    <span>"</span>
                  </blockquote>
                </a>
              </Recentie>

              <Recentie id={data.recenties[3].name}>
                <a
                  href={data.recenties[3].link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <blockquote>
                    <span>"</span>
                    {data.recenties[3].recentie}
                    <span>"</span>
                  </blockquote>
                </a>
              </Recentie>
              <Recentie id={data.recenties[4].name}>
                <a
                  href={data.recenties[4].link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <blockquote>
                    <span>"</span>
                    {data.recenties[4].recentie}
                    <span>"</span>
                  </blockquote>
                </a>
              </Recentie>
            </RecentiesFlexWrapper>
          </div>
        </div>
      </FlexWrapper>

      <div className="bgBot">
        <Contact title={data.titles.section6} mail={data.contactinfo.email} />

        {/* contactform */}

        <Footer name={data.titles.siteTitle} mail={data.contactinfo.email} />
      </div>
    </div>
  );
}

export default App;
