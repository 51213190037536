import React from "react";
import styled from "styled-components";
import { data } from "../data/data";

const Wrapper = styled.div`
  background: ${data.colors.prim};
  display: flex;
  flex-direction: column;
  /* padding: 1rem; */
  margin: 2rem auto;
  border: 1px solid #000;
  box-shadow: 4px 4px 0px 0px ${data.colors.darkGrey};
  width:100%;
  max-width:1000px;


`;

const Banner = styled.div`
  display: flex;
  padding: 10px 3%;
`;

const BannerLogo = styled.div`
  background: url(/img/ContainerLogo.png);
  background-size: cover;
  min-width: 30px;
  min-height: 30px;
  box-shadow: 1px 1px 0px 0px #333333aa;
`;

const Lines = styled.div`
  width: 100%;
  padding: 5px 3%;
`;

const Line = styled.div`
  margin: auto;
  height: 3px;
  border-bottom: 1px solid ${data.colors.black};
`;

const BannerText = styled.div`
  font-family: ${data.fonts.prim};
  font-size: 30px;
  margin-left: auto;
`;

const Content = styled.div`
  padding: 1rem ;
`;

const Footer = styled.div`
  background: url(/img/ContainerFooter.jpg);
  background-size: cover;
  height: 40px;
`;

const OuterDiv = styled.div`
  padding-top:40px;
  margin:1rem;
  flex:1;
`;
let Section = (props) => (
  <OuterDiv style = {{'minWidth':props.min}} id={props.id}>
    {/* <h2>{props.id}</h2> */}
  <Wrapper>
    {/* Banner */}
    <Banner>
      <BannerLogo />
      <Lines>
        <Line />
        <Line />
        <Line />
        <Line />
        <Line />
      </Lines>
      <BannerText>{props.id} </BannerText>
    </Banner>
    {/* content */}
    <Content>{props.children}</Content>
    {/* footer */}
    <Footer/>
  </Wrapper>
  </OuterDiv> 
);

export default Section;
