import React from "react";
import styled from "styled-components";

import { data } from "../data/data";
const Input = styled.input`
  border: 2px solid ${data.colors.black};
  height: 2rem;
  padding: 5px;
  min-width: 250px;
  outline: none;
  font-family:${data.fonts.prim};

  &:focus {
    border-color: ${data.colors.sec};
  }
`;
const Textarea = styled.textarea`
  border: 2px solid ${data.colors.black};
  padding: 5px;
  min-width: 250px;
  resize: vertical;
  height: 210px;
  outline:none;
  &:focus {
    border-color: ${data.colors.sec};
  }
`;
const FormContainer = styled.form`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width:300px;
`;

const FormItem = styled.div`
  margin-bottom: 10px;
`;

const Submit = styled.button`
  background:${data.colors.sec};
  box-shadow: 2px 2px #33333333;
  font-size:1rem;
  font-family:${data.fonts.prim};
  min-width: 250px;
  outline: none;
  width: 100%;
  height:2rem;
  border: 2px solid ${data.colors.black};

`

export let Form = (props) => {
  return (

    <FormContainer name="contact" method="post">
      <Input type="hidden" name="form-name" value="contact" />
    <FormItem>
      <label><Input type="text" placeholder="naam:" name="name"/></label>
    </FormItem>
    <FormItem>
      <label><Input type="email" placeholder="e-mail:" name="email"/></label>
    </FormItem>
    <FormItem>
      <label><Textarea placeholder="Bericht" name="message"></Textarea></label>
    </FormItem>
    <FormItem>
      <Submit type="submit">Verstuur</Submit>
    </FormItem>
  </FormContainer>

  );
};
