import React from "react";
import styled from "styled-components";
import { data } from "../data/data";

const OuterDiv = styled.div`
  margin-top: 40px;
  margin: 20px;
  min-width: 90%;
  @media screen and (min-width: 500px) {
    min-width:0   ;

  }
`;
const Wrapper = styled.div`
  background: ${data.colors.prim};
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 1rem auto;
  border: 1px solid #000;
  box-shadow: 4px 4px 0px 0px ${data.colors.darkGrey};

  @media screen and (min-width: 500px) {
  width: 210px;
  }
`;

const Banner = styled.div`
  display: flex;
  padding: 10px 3%;
`;

const BannerLogo = styled.div`
  background: url(/img/ContainerLogo.png);
  background-size: cover;
  min-width: 30px;
  min-height: 30px;
  box-shadow: 1px 1px 0px 0px #333333aa;
`;

const Lines = styled.div`
  width: 100%;
  padding: 5px 3%;
`;

const Line = styled.div`
  margin: auto;
  height: 3px;
  border-bottom: 1px solid ${data.colors.black};
`;

const BannerText = styled.div`
  font-family: ${data.fonts.prim};
  font-size: 1rem;
  margin-left: auto;
  padding-top: 5px;
  min-width:fit-content;
`;

const Content = styled.div`
  margin: 1rem 0.3rem;
  padding: 0 3%;
`;

const Footer = styled.div`
  background: url(/img/ContainerFooter.jpg);
  background-size: cover;
  height: 40px;
`;


let Stars = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
`;

let Logo = styled.img`
  max-height: 20px;
  padding-right: 5px;
`;
export let Recentie = (props) => (
  <OuterDiv id={props.id}>
    <Wrapper>
      {/* Banner */}
      <Banner>
        <BannerLogo />
        <Lines>
          <Line />
          <Line />
          <Line />
          <Line />
          <Line />
        </Lines>
        <BannerText>{props.id} </BannerText>
      </Banner>
      {/* content */}
      <Content>{props.children}</Content>
      <Stars>
        <Logo src="/img/star.png"></Logo>
        <Logo src="/img/star.png"></Logo>
        <Logo src="/img/star.png"></Logo>
        <Logo src="/img/star.png"></Logo>
        <Logo src="/img/star.png"></Logo>
        <Logo src="/img/star.png"></Logo>
        <Logo src="/img/star.png"></Logo>
      </Stars>
      {/* footer */}
      <Footer />
    </Wrapper>
  </OuterDiv>
);
