import React from 'react'
import styled from 'styled-components';

const A = styled.a`
  display:flex;
  justify-content:center;
  padding:0;
  margin-top:15px;
  `;
const Img = styled.img`
  max-width:130px;
  max-height:40px;


`;

export let Buttonlink = (props) => {
  return (
    <A href={props.href} target="_blank" rel="noopener noreferrer"
    >
      <Img src={props.src} alt=" "/>
    </A>
  )
 }

