// colors
export let data = {
  colors: {
    prim: "#C37F7F",
    // prim: "#C47F7E",
    sec: "#407EC7",
    tert: "#E9D5A7",
    thickmist: "#ffffffee",
    mist: "#ffffff66",
    white: "#fff",
    black: "#000",
    darkGrey: "#333",
  },
  fonts: {
    prim: "VCR",
    Sec: "manrope",
  },
  titles: {
    siteTitle: "Dikke Hanna",
    slogan: "",
    section1: "Video's",
    section2: "Muziek",
    section3: "Bio",
    section4: "Instagram",
    section5: "Recensies",
    section6: "Contact",
  },
  imgpath: {
    banner: "/img/BannerBackground.jpg",
    logo: "/img/DikkeHanna.png",
    facebook: "/img/FacebookLogo.png",
    instagram: "/img/InstagramLogo.png",
    youtube: "/img/YoutubeLogo.png",
    spotify: "/img/SpotifyLogo.png",
    soundcloud: "/img/SoundcloudLogo.png",
    email: "/img/mail.png",
    btnvolgen: "/img/volgen.png",
    btnabonneren: "/img/Abonneren.png",
    btnarrowleft: "/img/ArrowLeft.png",
    btnarrowright: "/img/ArrowRight.png",
  },
  stories: {
    bio:
      "DikkeHanna is de artiestennaam van Hanna van Koldam uit Leeuwarden. Ze relativeert serieuze onderwerpen door ze op humoristische wijze te bespreken. Ze schrijft liedjes die haar moeder niet mee wil zingen en de videoclips worden al niet eens meer bekeken. Sinds ze op zichzelf woont is dit uitschot van het gezin eindelijk de leukste thuis.",
    story2:
      "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius nisi sint iste libero debitis doloribus similique eveniet quam magnam quia. Lorem ipsum ",
  },

  videopath: {
    video1: "https://www.youtube.com/embed/0RzHfNiwmGY",
    video2: "https://www.youtube.com/embed/Vb8NvBt9x4U",
    video3: "https://www.youtube.com/embed/C7AER_tbxuo",
  },
  spotifypath: {
    spotify1: "https://open.spotify.com/embed/artist/5LlzZppCKp0kzOJlJyygpS",
  },
  contactinfo: {
    email: "info@Dikkehanna.nl",
    facebook: "https://www.facebook.com/dikkehanna",
    instagram: "https://www.instagram.com/dikkehanna/",
    spotify:
      "https://open.spotify.com/artist/5LlzZppCKp0kzOJlJyygpS?si=MraZCta8R8eHD3SABVWFgg&nd=1",
    youtube: "https://www.youtube.com/user/hannavankoldam",
    soundcloud: "https://soundcloud.com/dikke-hanna",
  },
  recenties: {
    1: {
      name: "Suksawat",
      recentie:
        "‘Instaslet’ is een absolute internet-hit en ‘Kinderwens’ een instant klassieker.",
      link:
        "https://www.suksawat.nl/instaslet-en-aandachtshoeren-studente-van-minerva-in-leeuwarden-verovert-internet/#.WUfRj8YXDXk.facebook",
    },
    2: {
      name: "Thenextgig.nl",
      recentie:
        "Vragen stellen bij maatschappelijke zaken door met absurditeit, daar kleine en vrolijke gekke liedjes over te maken. Ja graag.",
      link:
        "https://thenextgig.nl/van-plee-papier-naar-menstruatie-met-dikke-hanna/",
    },
    3: {
      name: "Leeuwarder Courant",
      recentie: "Heel aardig",
      link:
        "https://www.lc.nl/friesland/Instaslet-zangeres-Hanna-van-Koldam-uit-Makkum-over-hit-school-en-toekomst-22405446.html",
    },
    4: {
      name: "Trouw",
      recentie: "(19)",
      link:
        "https://www.trouw.nl/nieuws/vrouwen-doen-net-zo-hard-aan-slutshaming~bcaa7819/",
    },
    5: {
      name: "HPdetijd.nl",
      recentie: "Kaandorp-achtig ukulele meisje",
      link: "https://www.hpdetijd.nl/2017-06-24/instaslet/",
    },
  },
};
